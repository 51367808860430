import * as React from "react"
import { Col, Row } from 'react-bootstrap'
import Contact from "../../components/Contact"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

const ContactPage = () => (
  <Layout>
    <Seo title="Contact Us" description="We can't write about it all fast enough. Let's connect to see if we can help you where you are stuck." />
    <Row className="py-4">
      <h1 className="display-6 fw-bold text-center">We know the difficulties of building software.</h1>
    </Row>
    <Row className="justify-content-center">
      <Col lg={12} className="px-5">
        <p>There's only so many topics that we can write about. We're always interested in hearing from our visitors and iterating our content based on what our visitors are saying. We'd love to hear from you if you have thoughts on what we're sharing.</p>
        <p>Do you have a tricky situation that you're trying to figure out how to solve? Would you like to run your problem by us? Feel free to fill out the form below and we can chat.</p>
      </Col>
    </Row>
    <Contact />
  </Layout>
)

export default ContactPage
